import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { useSelector } from 'react-redux';
import { Result } from 'antd';

import { history } from '@/src/store';
import Login from '@/src/pages/Login/index';
import NotFound from '@/src/pages/404';
import Orders from '@/src/pages/Orders/index';
import Order from '@/src/pages/Order/index';
import Subscriptions from '@/src/pages/Subscriptions/index';
import Subscription from '@/src/pages/Subscriptions/Subscription/index';
import Customers from '@/src/pages/Customers/index';
import CustomerDetails from '@/src/pages/Customers/Customer/index';
import Highlights from '@/src/pages/Highlights/index';
import EditHighlight from '@/src/pages/Highlights/Edit/index';
import NewHighlight from '@/src/pages/Highlights/New/index';
import Promotions from '@/src/pages/Promotions/index';
import Promotion from '@/src/pages/Promotions/containers/Promotion/index';
import NewPromotion from '@/src/pages/Promotions/containers/NewPromo/index';
import Referral from '@/src/pages/Referral';
import Users from '@/src/pages/Users';
import NewUser from '@/src/pages/Users/NewUser';
// import Reports from '@/src/pages/Reports';
// import GeneralReport from '@/src/pages/Reports/General';
// import GeneralReportDetailed from '@/src/pages/Reports/General/Detailed';
// import GeneralReportComparative from '@/src/pages/Reports/General/Comparative';
// import GeneralReportIfood from '@/src/pages/Reports/General/Ifood';
// import ReportsOperation from '@/src/pages/Reports/Operations';
// import ReportsOperationZeeMan from '@/src/pages/Reports/Operations/ZeeMan';
// import ReportsOpsStockCoverage from '@/src/pages/Reports/Operations/StockCoverage';
// import ReportsOperationTechOps from '@/src/pages/Reports/Operations/TechOps';
// import ReportsGrowth from '@/src/pages/Reports/Growth';
import ReportsZpar from '@/src/pages/Reports/Growth/Zpar';
import ReportsNPS from '@/src/pages/Reports/Growth/NPS';
import ReportsNPSRetail from '@/src/pages/Reports/Growth/NPS/Retail';
import ReportSales from '@/src/pages/Reports/Invoicing/Sales';
import ReportProducts from '@/src/pages/Reports/Invoicing/Products';
import ReportCategories from '@/src/pages/Reports/Invoicing/Categories';
import ReportPayments from '@/src/pages/Reports/Invoicing/Payments';
import ReportInfluencers from '@/src/pages/Reports/Invoicing/Influencers';
import ReportCustomers from '@/src/pages/Reports/Conversion/Customers';
import ReportOperationalTimes from '@/src/pages/Reports/Operational/Times';
import ReportOperationalCancellations from '@/src/pages/Reports/Operational/Cancellations';
// import ReportsPlatforms from '@/src/pages/Reports/Growth/Platforms';
// import ReportsCommercial from '@/src/pages/Reports/Commercial';
// import ReportsCommerciaSuppliers from '@/src/pages/Reports/Commercial/Suppliers';
// import ReportsCommerciaNotifyMe from '@/src/pages/Reports/Commercial/NotifyMe';
// import ReportsSales from '@/src/pages/Reports/Sales';
// import ReportsCouponsCredits from '@/src/pages/Reports/CouponsCredits';
// import ReportPathSource from '@/src/pages/Reports/PathSource';
import ChangePassword from '@/src/pages/ChangePassword';
import ForgotPassword from '@/src/pages/ForgotPassword';
import User from '@/src/pages/Users/User';
import NewAisle from '@/src/pages/Aisles/New';
import EditAisle from '@/src/pages/Aisles/Edit';
import ListAisles from '@/src/pages/Aisles';
import Categories from '@/src/pages/Categories';
import NewCategory from '@/src/pages/Categories/New';
import EditCategory from '@/src/pages/Categories/Edit';
import Attributes from '@/src/pages/Attributes/AllAttributes';
import NewAttribute from '@/src/pages/Attributes/New';
import EditAttribute from '@/src/pages/Attributes/Edit';
import Departments from '@/src/pages/Departments/AllDepartments';
import NewDepartment from '@/src/pages/Departments/New';
import EditDepartment from '@/src/pages/Departments/Edit';
import ExpeditionPage from '@/src/pages/Expedition';
import Products from '@/src/pages/Products';
import EditProduct from '@/src/pages/Products/Edit';
import Shipping from '@/src/pages/Shipping';
// import EditShipping from '@/src/pages/Shipping/Edit';
import NewShipping from '@/src/pages/Shipping/New';
import Hubs from '@/src/pages/Hubs';
import EditHub from '@/src/pages/Hubs/Edit';
import Policies from '@/src/pages/Policies';
import AllPriceTables from '@/src/pages/Policies/AllPriceTables';
import Policy from '@/src/pages/Policies/containers/Policy';
import PriceIndexing from '@/src/pages/Policies/PriceIndexing';
import Recommendations from '@/src/pages/Recommendations/index';
import NewRecommendation from '@/src/pages/Recommendations/containers/Recommendation/index';
// import RecommendationDetails from '@/src/pages/Recommendations/containers/Recommendation/index';
import Deliverers from '@/src/pages/Deliverers/index';
import Deliverer from '@/src/pages/Deliverers/Deliverer/index';
import Sizes from '@/src/pages/Sizes/index';
import EditSizes from '@/src/pages/Sizes/Edit/index';
import Manufacturers from '@/src/pages/Manufacturers/index';
import EditManufacturer from '@/src/pages/Manufacturers/Edit';
import NewManufacturer from '@/src/pages/Manufacturers/New';
import GatewayStores from '@/src/pages/GatewayStores';
import SkusPetz from '@/src/pages/SkusPetz';
import SkuPetz from '@/src/pages/SkusPetz/SkuPetz';
import ListAssemblings from '@/src/pages/Assemblings';
import EditAssembling from '@/src/pages/Assemblings/Edit';
import NewAssembling from '@/src/pages/Assemblings/New';
import Layout from '@/src/HOC/Layout/index';

import * as modules from '@/src/globals/permissionsModules';
import mediaQueries from '@/src/globals/mediaQueries';
import usePermissions from '@/src/hooks/usePermissions';
import getDeviceData from '@/src/utils/device';
import { getToken } from '@/src/services/auth';
import {
  BreakpointProvider,
  useBreakpoint
} from '@/src/context/BreakpointContext';

const PrivateRoute = ({ component: Component, module, editOnly, ...rest }) => {
  const { account } = useSelector(state => state.account);
  const { pathname } = useSelector(state => state.router.location);

  // caso seja uma rota visível apenas para usuários com permissão de edição (exemplo: Nova Categoria), adicionamos a propriedade editOnly na PrivateRoute para verificar a permissão com o método adequado

  const { editPermission, routePermission } = usePermissions();
  const isAllowed = editOnly ? editPermission(module) : routePermission(module);

  const hasToken = getToken();

  const breakpoint = useBreakpoint();

  const deviceData = navigator?.userAgent
    ? getDeviceData(navigator.userAgent)
    : {};
  const mobileDevice = deviceData?.os === 'iOS' || deviceData?.os === 'Android';

  return (
    <Route
      {...rest} //eslint-disable-line
      render={props =>
        hasToken ? (
          <>
            {breakpoint.mobile &&
              mobileDevice &&
              pathname !== '/expedicao' &&
              !pathname.includes('/hubs') &&
              pathname !== '/alterar-senha' &&
              pathname !== '/' && <Redirect to="/not-found" />}
            <Layout>
              {account && isAllowed ? (
                <Component {...props} />
              ) : (
                <Result
                  status="403"
                  title="Oops..."
                  subTitle="Você não tem permissão para acessar esse conteúdo."
                />
              )}
            </Layout>
          </>
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

const Routes = () => {
  return (
    <BreakpointProvider queries={mediaQueries}>
      <ConnectedRouter history={history}>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route path="/signup" render={() => <h1>SignUp</h1>} />
          <PrivateRoute
            path="/clientes"
            exact
            component={Customers}
            module={modules.CUSTOMERS_VIEW}
          />
          <PrivateRoute
            path="/destaques"
            exact
            component={Highlights}
            module={modules.CATALOG}
            type="index"
            editOnly
          />
          <PrivateRoute
            path="/destaques/novo"
            exact
            component={NewHighlight}
            module={modules.CATALOG}
            type="index"
            editOnly
          />
          <PrivateRoute
            path="/destaques/:id"
            exact
            component={EditHighlight}
            module={modules.CATALOG}
            type="index"
            editOnly
          />
          <PrivateRoute
            path="/entregadores"
            exact
            component={Deliverers}
            module={modules.DISPATCH_DELIVERERS}
          />
          <PrivateRoute
            path="/entregadores/:id"
            exact
            component={Deliverer}
            module={modules.DISPATCH_DELIVERERS}
          />
          <PrivateRoute
            path="/entregadores/novo"
            exact
            component={Deliverer}
            module={modules.DISPATCH_DELIVERERS}
          />
          <PrivateRoute
            path="/departamentos"
            exact
            component={Departments}
            module={modules.CATALOG}
            editOnly
          />
          <PrivateRoute
            path="/departamentos/novo"
            exact
            component={NewDepartment}
            module={modules.CATALOG}
            editOnly
          />
          <PrivateRoute
            path="/departamentos/:id"
            exact
            component={EditDepartment}
            module={modules.CATALOG}
            editOnly
          />
          <PrivateRoute
            path="/categorias"
            exact
            component={Categories}
            module={modules.CATALOG}
          />
          <PrivateRoute
            path="/categorias/nova"
            exact
            component={NewCategory}
            module={modules.CATALOG}
            editOnly
          />
          <PrivateRoute
            path="/categorias/:id"
            exact
            component={EditCategory}
            module={modules.CATALOG}
          />
          <PrivateRoute
            path="/colecoes"
            exact
            component={ListAssemblings}
            module={modules.CATALOG}
            editOnly
          />
          <PrivateRoute
            path="/colecoes/nova"
            exact
            component={NewAssembling}
            module={modules.CATALOG}
            editOnly
          />
          <PrivateRoute
            path="/colecoes/:id"
            exact
            component={EditAssembling}
            module={modules.CATALOG}
            editOnly
          />
          <PrivateRoute
            path="/corredores/novo"
            exact
            component={NewAisle}
            module={modules.CATALOG}
            editOnly
          />
          <PrivateRoute
            path="/corredores/:id"
            exact
            component={EditAisle}
            module={modules.CATALOG}
            editOnly
          />
          <PrivateRoute
            path="/corredores"
            exact
            component={ListAisles}
            module={modules.CATALOG}
            editOnly
          />
          <PrivateRoute
            path="/atributos"
            exact
            component={Attributes}
            module={modules.CATALOG}
            editOnly
          />
          <PrivateRoute
            path="/atributos/novo"
            exact
            component={NewAttribute}
            module={modules.CATALOG}
            editOnly
          />
          <PrivateRoute
            path="/atributos/:id"
            exact
            component={EditAttribute}
            module={modules.CATALOG}
            editOnly
          />
          <PrivateRoute
            path="/clientes/:id"
            exact
            component={CustomerDetails}
            module={modules.CUSTOMERS_VIEW}
          />
          <PrivateRoute
            path="/politicas"
            exact
            component={Policies}
            module={modules.CATALOG}
            editOnly
          />
          <PrivateRoute
            path="/tabelas"
            exact
            component={AllPriceTables}
            module={modules.CATALOG}
            editOnly
          />
          <PrivateRoute
            path="/precos"
            exact
            component={PriceIndexing}
            module={modules.CATALOG}
            editOnly
          />
          <PrivateRoute
            path="/politicas/:id"
            exact
            component={Policy}
            module={modules.CATALOG}
            editOnly
          />
          <PrivateRoute
            path="/politicas/nova"
            exact
            component={Policy}
            module={modules.CATALOG}
            editOnly
          />
          <PrivateRoute
            path="/promocoes"
            exact
            component={Promotions}
            module={modules.PROMOTIONS}
          />
          <PrivateRoute
            path="/promocoes/nova"
            exact
            component={NewPromotion}
            module={modules.PROMOTIONS}
          />
          <PrivateRoute
            path="/promocoes/:promoId"
            exact
            component={Promotion}
            module={modules.PROMOTIONS}
          />
          <PrivateRoute
            path="/recomendacoes"
            exact
            component={Recommendations}
            module={modules.CATALOG}
            editOnly
          />
          <PrivateRoute
            path="/recomendacoes/nova"
            exact
            component={NewRecommendation}
            module={modules.CATALOG}
            editOnly
          />
          <PrivateRoute
            path="/recomendacoes/:id"
            exact
            component={NewRecommendation}
            module={modules.CATALOG}
            editOnly
          />
          <PrivateRoute
            path="/pedidos"
            exact
            component={Orders}
            module={modules.ORDERS}
          />
          <PrivateRoute
            path="/pedidos/:orderId"
            exact
            component={Order}
            module={modules.ORDERS}
          />
          <PrivateRoute
            path="/assinaturas"
            exact
            component={Subscriptions}
            module={modules.SUBSCRIPTIONS_VIEW}
          />
          <PrivateRoute
            path="/assinaturas/:id"
            exact
            component={Subscription}
            module={modules.SUBSCRIPTIONS_VIEW}
          />
          <PrivateRoute
            path="/produtos"
            exact
            component={Products}
            module={modules.CATALOG}
          />
          <PrivateRoute
            path="/produtos/:id"
            exact
            component={EditProduct}
            module={modules.CATALOG}
          />
          <PrivateRoute
            path="/skus-petz"
            exact
            component={SkusPetz}
            module={modules.CATALOG}
          />
          <PrivateRoute
            path="/skus-petz/novo"
            exact
            component={SkuPetz}
            module={modules.CATALOG}
          />
          <PrivateRoute
            path="/skus-petz/:id"
            exact
            component={SkuPetz}
            module={modules.CATALOG}
          />
          <PrivateRoute
            path="/indicacoes"
            exact
            component={Referral}
            module={modules.REFERRAL}
          />
          {/* <PrivateRoute
            path="/relatorios/geral"
            exact
            component={GeneralReport}
            module={modules.REPORTS_GENERAL}
          />
          <PrivateRoute
            path="/relatorios/detalhado"
            exact
            component={GeneralReportDetailed}
            module={modules.REPORTS_GENERAL}
          />
          <PrivateRoute
            path="/relatorios/comparativo"
            exact
            component={GeneralReportComparative}
            module={modules.REPORTS_GENERAL}
          />
          <PrivateRoute
            path="/relatorios/ifood"
            exact
            component={GeneralReportIfood}
            module={modules.REPORTS_GENERAL}
          />
          <PrivateRoute
            path="/relatorios/operacoes"
            exact
            component={ReportsOperation}
            module={modules.REPORTS_OPERATIONS}
          />
          <PrivateRoute
            path="/relatorios/zeeman"
            exact
            component={ReportsOperationZeeMan}
            module={modules.REPORTS_OPERATIONS}
          />
          <PrivateRoute
            path="/relatorios/cobertura"
            exact
            component={ReportsOpsStockCoverage}
            module={modules.REPORTS_OPERATIONS}
          />
          <PrivateRoute
            path="/relatorios/tech-ops"
            exact
            component={ReportsOperationTechOps}
            module={modules.REPORTS_OPERATIONS}
          />
          <PrivateRoute
            path="/relatorios/growth"
            exact
            component={ReportsGrowth}
            module={modules.REPORTS_GROWTH}
          /> */}
          <PrivateRoute
            path="/relatorios/zpar"
            exact
            component={ReportsZpar}
            module={modules.REPORTS_GROWTH}
          />
          <PrivateRoute
            path="/relatorios/nps"
            exact
            component={ReportsNPS}
            module={modules.REPORTS_GROWTH}
          />
          <PrivateRoute
            path="/relatorios/nps/ecommerce"
            exact
            component={ReportsNPS}
            module={modules.REPORTS_GROWTH}
          />
          <PrivateRoute
            path="/relatorios/nps/varejo"
            exact
            component={ReportsNPSRetail}
            module={modules.REPORTS_GROWTH}
          />
          <PrivateRoute
            path="/relatorios/faturamento/vendas"
            exact
            component={ReportSales}
            module={modules.REPORTS_GROWTH}
          />
          <PrivateRoute
            path="/relatorios/faturamento/produtos"
            exact
            component={ReportProducts}
            module={modules.REPORTS_GROWTH}
          />
          <PrivateRoute
            path="/relatorios/faturamento/categorias-e-marcas"
            exact
            component={ReportCategories}
            module={modules.REPORTS_GROWTH}
          />
          <PrivateRoute
            path="/relatorios/faturamento/pagamentos"
            exact
            component={ReportPayments}
            module={modules.REPORTS_GROWTH}
          />
          <PrivateRoute
            path="/relatorios/faturamento/influenciadores"
            exact
            component={ReportInfluencers}
            module={modules.REPORTS_GROWTH}
          />
          <PrivateRoute
            path="/relatorios/conversao-e-aquisicao/clientes"
            exact
            component={ReportCustomers}
            module={modules.REPORTS_GROWTH}
          />
          <PrivateRoute
            path="/relatorios/operacional/tempos"
            exact
            component={ReportOperationalTimes}
            module={modules.REPORTS_GROWTH}
          />
          <PrivateRoute
            path="/relatorios/operacional/cancelamentos"
            exact
            component={ReportOperationalCancellations}
            module={modules.REPORTS_GROWTH}
          />
          {/* <PrivateRoute
            path="/relatorios/plataformas"
            exact
            component={ReportsPlatforms}
            module={modules.REPORTS_GROWTH}
          />
          <PrivateRoute
            path="/relatorios/comercial"
            component={ReportsCommercial}
            module={modules.REPORTS_COMMERCIAL}
          />
          <PrivateRoute
            path="/relatorios/fornecedores"
            component={ReportsCommerciaSuppliers}
            module={modules.REPORTS_COMMERCIAL}
          />
          <PrivateRoute
            path="/relatorios/avise-me"
            component={ReportsCommerciaNotifyMe}
            module={modules.REPORTS_COMMERCIAL}
          />
          <PrivateRoute
            path="/relatorios/path-source"
            exact
            component={ReportPathSource}
            module={modules.REPORTS_GENERAL}
          /> */}
          <PrivateRoute
            path="/usuarios"
            exact
            component={Users}
            module={modules.USERS}
          />
          <PrivateRoute
            path="/usuarios/novo"
            exact
            component={NewUser}
            module={modules.USERS}
          />
          <PrivateRoute
            path="/usuarios/:userId"
            component={User}
            module={modules.USERS}
          />
          <PrivateRoute
            path="/expedicao"
            exact
            component={ExpeditionPage}
            module={modules.DISPATCH_ORDERS}
          />
          <PrivateRoute
            path="/hubs"
            exact
            component={Hubs}
            module={modules.DISPATCH_HUBS}
          />
          <PrivateRoute
            path="/hubs/:id"
            exact
            component={EditHub}
            module={modules.DISPATCH_HUBS}
          />
          <PrivateRoute
            path="/embarques"
            exact
            component={Shipping}
            module={modules.DISPATCH_EMBARKS}
          />
          <PrivateRoute
            path="/embarques/novo"
            exact
            component={NewShipping}
            module={modules.DISPATCH_EMBARKS}
          />
          {/* <PrivateRoute
            path="/embarques/:id"
            exact
            component={EditShipping}
            module={modules.DISPATCH_EMBARKS}
          /> */}
          <PrivateRoute
            path="/alterar-senha"
            exact
            component={ChangePassword}
            module={modules.PASSWORD}
          />
          <PrivateRoute
            path="/tamanhos"
            exact
            component={Sizes}
            module={modules.CATALOG}
            editOnly
          />
          <PrivateRoute
            path="/tamanhos/racas/:id"
            exact
            component={EditSizes}
            module={modules.CATALOG}
            editOnly
          />
          <PrivateRoute
            path="/tamanhos/acessorios/:id"
            exact
            component={EditSizes}
            module={modules.CATALOG}
            editOnly
          />
          <PrivateRoute
            path="/fabricantes"
            exact
            component={Manufacturers}
            module={modules.CATALOG}
          />
          <PrivateRoute
            path="/fabricantes/novo"
            exact
            component={NewManufacturer}
            module={modules.CATALOG}
            editOnly
          />
          <PrivateRoute
            path="/fabricantes/:id"
            exact
            component={EditManufacturer}
            module={modules.CATALOG}
          />
          <PrivateRoute
            path="/lojas-gateway"
            exact
            component={GatewayStores}
            module={modules.GATEWAY_STORES}
          />
          <Route path="/resetar-senha" exact component={ForgotPassword} />
          <Route path="*" component={NotFound} />
        </Switch>
      </ConnectedRouter>
    </BreakpointProvider>
  );
};

PrivateRoute.defaultProps = {
  location: {},
  editOnly: false
};

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})])
    .isRequired,
  location: PropTypes.objectOf(PropTypes.string),
  module: PropTypes.string.isRequired,
  editOnly: PropTypes.bool
};

export default Routes;
