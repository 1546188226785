import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Col, Spin, Input, Icon, Modal, Avatar, Radio } from 'antd';

import CollectItem from '@/src/components/Expedition/Modal/CollectTable/CollectItem';

import {
  getProductScan,
  setForceCollectEan,
  setScanInput
} from '@/src/store/modules/expedition/slice';

import { useBreakpoint } from '@/src/context/BreakpointContext';

import '../Modal.scss';

const crypto = require('crypto');

const CollectTable = ({ setCollectBtnDisabled }) => {
  const [availableItems, setAvailableItems] = useState([]);

  const {
    collectedItems,
    contentLoading,
    scanLoading,
    scanInput,
    forceCollectEan,
    modalData: { order }
  } = useSelector(state => state.expedition);

  const dispatch = useDispatch();

  const breakpoint = useBreakpoint();

  const radioRef = useRef();

  const scanInputRef = useCallback(node => {
    if (node !== null) {
      node.input.setAttribute('inputmode', 'none');
      node.input.addEventListener('focus', () => {
        setTimeout(() => {
          node.input.setAttribute('inputmode', '');
        });
      });
      node.input.addEventListener('blur', () => {
        node.input.setAttribute('inputmode', 'none');
      });
      node.input.focus();
    }
  }, []);

  useEffect(() => {
    const itemsToCollect = order?.orderItems?.filter(
      item => item.quantity - item.returnedQuantity > 0
    );
    const fullyCollected = product => {
      const collected = collectedItems.orderItems.find(
        item => Number(item.itemId) === Number(product.id)
      );
      if (!collected) return false;
      return collected.done;
    };
    if (itemsToCollect?.length) {
      setAvailableItems(
        itemsToCollect.filter(item => {
          const isCollected = fullyCollected(item);
          return !isCollected;
        })
      );
      setCollectBtnDisabled(!itemsToCollect.every(fullyCollected));
    } else {
      setCollectBtnDisabled(true);
    }
    // eslint-disable-next-line
  }, [collectedItems, order]);

  const buildEncryptedData = ean => {
    const encryptedData = crypto.publicEncrypt(
      {
        key: process.env.REACT_APP_RSA_KEY,
        padding: crypto.constants.RSA_PKCS1_OAEP_PADDING,
        oaepHash: 'sha256'
      },
      // We convert the data string to a buffer using `Buffer.from`
      Buffer.from(ean)
    );
    return encryptedData;
  };

  const handleProductScan = evt => {
    dispatch(setScanInput(evt.target.value));
    if (!evt.target.value) return;
    const ean = evt.target.value;
    const isGift = order.orderItems.find(
      item =>
        item.skuEan === ean &&
        item.gift &&
        !collectedItems.orderItems.find(
          collect => Number(collect.itemId) === Number(item.id)
        )
    );
    let rupture = 0;
    const orderItem = order.orderItems.find(
      item =>
        item.skuEan === ean &&
        collectedItems.orderItems.find(
          collect => Number(collect.itemId) === Number(item.id)
        )
    );
    if (orderItem) {
      const collectedItem = collectedItems.orderItems.find(
        collect => Number(collect.itemId) === Number(orderItem.id)
      );
      if (collectedItem.rupture < collectedItem.total)
        rupture = collectedItem.rupture;
    }

    const encryptedData = buildEncryptedData(ean);

    const payload = {
      data: {
        order_id: Number(order.id),
        barcode: encryptedData.toString('base64'),
        quantity: 1,
        is_gift: !!isGift
      },
      ean,
      rupture
    };
    // console.log(payload);
    dispatch(getProductScan(payload));
  };

  const handleForceCollect = () => {
    const { value } = radioRef.current.state;
    const id = value.split('-')[0];
    const ean = value.split('-')[1];
    const isGift = order.orderItems.find(item => item.id === id && item.gift);
    let rupture = 0;
    const orderItem = order.orderItems.find(
      item =>
        item.skuEan === ean &&
        collectedItems.orderItems.find(
          collect => Number(collect.itemId) === Number(item.id)
        )
    );
    if (orderItem) {
      const collectedItem = collectedItems.orderItems.find(
        collect => Number(collect.itemId) === Number(orderItem.id)
      );
      if (collectedItem.rupture) rupture = collectedItem.rupture;
    }

    const encryptedEan = buildEncryptedData(ean);
    const encryptedDivergentEan = buildEncryptedData(forceCollectEan);

    const payload = {
      data: {
        order_id: Number(order.id),
        barcode: encryptedEan.toString('base64'),
        quantity: 1,
        divergent_barcode: encryptedDivergentEan.toString('base64'),
        is_gift: !!isGift
      },
      ean,
      rupture
    };
    dispatch(getProductScan(payload));
    dispatch(setForceCollectEan(''));
  };

  return (
    <>
      <Modal
        title="FORÇAR A COLETA"
        destroyOnClose
        visible={forceCollectEan !== ''}
        onCancel={() => dispatch(setForceCollectEan(''))}
        onOk={handleForceCollect}
        okText="Forçar a coleta"
        className="centered-footer ant-modal-scan"
        okButtonProps={{
          style: {
            backgroundColor: '#F9355C',
            borderColor: '#F9355C',
            padding: '0 12px'
          }
        }}
        cancelButtonProps={{
          style: { padding: '0 12px' }
        }}
      >
        <Row>
          <span>
            O EAN bipado não faz parte do pedido. Qual produto deseja coletar?
          </span>
        </Row>
        <Radio.Group ref={radioRef}>
          {availableItems.map(item => {
            return (
              <Row
                type="flex"
                justify="center"
                align="middle"
                style={{ margin: '12px 0' }}
              >
                <Col span={2}>
                  <Radio value={`${item.id}-${item.skuEan}`} />
                </Col>
                <Col span={6}>
                  <Avatar
                    style={{
                      width: '100%',
                      height: 'auto'
                    }}
                    shape="square"
                    size="100%"
                    src={item.skuImage}
                    icon="camera"
                    className="img-placeholder"
                  />
                </Col>
                <Col span={16}>
                  <span style={{ textAlign: 'left' }}>
                    {item.skuExternalId} - {item.skuName}
                  </span>
                </Col>
              </Row>
            );
          })}
        </Radio.Group>
      </Modal>
      <Spin spinning={contentLoading}>
        <div className="modal-table__wrapper">
          {breakpoint.lg ? (
            <div className="modal-table__header">
              <Row>
                <Col span={3}>
                  <div className="modal-table__header-cell">Foto</div>
                </Col>
                <Col span={4}>
                  <div className="modal-table__header-cell">SKU</div>
                </Col>
                <Col span={5}>
                  <div className="modal-table__header-cell">EAN</div>
                </Col>
                <Col span={6}>
                  <div className="modal-table__header-cell">Nome produto</div>
                </Col>
                <Col span={3}>
                  <div className="modal-table__header-cell">Qnt</div>
                </Col>
                <Col span={3}>
                  <div className="modal-table__header-cell">Alerta</div>
                </Col>
              </Row>
            </div>
          ) : (
            <div style={{ display: 'flex', padding: '12px 10px' }}>
              <Input
                ref={scanInputRef}
                value={scanInput}
                onChange={handleProductScan}
                placeholder="Clique aqui para bipar o produto"
                loading={scanLoading}
                suffix={
                  <Icon
                    type={scanLoading ? 'loading' : 'barcode'}
                    style={{ display: 'flex', fontSize: 18 }}
                  />
                }
              />
            </div>
          )}
          {contentLoading ? (
            <>
              <CollectItem />
            </>
          ) : (
            order?.orderItems
              ?.filter(item => item.quantity - item.returnedQuantity > 0)
              .map(item => {
                return (
                  <CollectItem
                    id={item.id}
                    sku={item.skuExternalId || item.skuCode}
                    ean={item.skuEan}
                    productName={item.skuName}
                    productImg={item.skuImage || ''}
                    totalProductQty={item.quantity - item.returnedQuantity}
                    scannedQty={item.scannedQuantity}
                    orderId={item.orderId}
                    isGift={item.gift}
                  />
                );
              })
          )}
        </div>
      </Spin>
    </>
  );
};

CollectTable.defaultProps = {
  setCollectBtnDisabled: () => {}
};

CollectTable.propTypes = {
  setCollectBtnDisabled: PropTypes.func
};

export default CollectTable;
