import JadlogLogo from '@/src/assets/images/carrier/Jadlog.png';
import CarriersLogo from '@/src/assets/images/carrier/Carriers.png';
import SedexLogo from '@/src/assets/images/carrier/Sedex.png';
import PacLogo from '@/src/assets/images/carrier/Pac.png';
import CorreiosLogo from '@/src/assets/images/carrier/Correios.png';
import R1Logo from '@/src/assets/images/carrier/R1.png';
import MagalogLogo from '@/src/assets/images/carrier/Magalog.png';
import QualityLogo from '@/src/assets/images/carrier/Quality.png';
import DominaLogo from '@/src/assets/images/carrier/Domina.png';

const carrierImg = {
  jadlog: JadlogLogo,
  carriers: CarriersLogo,
  sedex: SedexLogo,
  pac: PacLogo,
  correios: CorreiosLogo,
  r1: R1Logo,
  magalog: MagalogLogo,
  quality: QualityLogo,
  domina: DominaLogo
};

const getCarrierImage = name => {
  const nameToLowerCase = name.toLowerCase();
  if (nameToLowerCase.includes('jadlog') || nameToLowerCase === 'standard') {
    return carrierImg.jadlog;
  }
  if (nameToLowerCase.includes('carriers')) {
    return carrierImg.carriers;
  }
  if (nameToLowerCase.includes('sedex')) {
    return carrierImg.sedex;
  }
  if (nameToLowerCase.includes('pac')) {
    return carrierImg.pac;
  }
  if (nameToLowerCase.includes('correios')) {
    return carrierImg.correios;
  }
  if (nameToLowerCase.includes('r1')) {
    return carrierImg.r1;
  }
  if (nameToLowerCase.includes('magalu')) {
    return carrierImg.magalog;
  }
  if (nameToLowerCase.includes('quality')) {
    return carrierImg.quality;
  }
  if (nameToLowerCase.includes('dominalog')) {
    return carrierImg.domina;
  }
  return ''; // Retorna uma imagem padrão se a transportadora não for reconhecida
};

export default getCarrierImage;
