import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import {
  Row,
  Breadcrumb,
  Col,
  Card,
  Form,
  Input,
  InputNumber,
  Button,
  Skeleton,
  Menu,
  Dropdown,
  Icon,
  Select
} from 'antd';
import PropTypes from 'prop-types';
import Audit from '@/src/components/Audit/index';
import {
  getSkuPetz,
  createSkuPetz,
  updateSkuPetz,
  getWarehouseSaleChannels
} from '@/src/store/modules/skus-petz/slice';
import { handleSelectSearch } from '@/src/utils/searchUtils';
import { ZEEDOG_STORE } from '@/src/globals/constants';

const SkuPetz = ({ form }) => {
  const dispatch = useDispatch();
  const { getFieldDecorator, validateFields, setFieldsValue } = form;
  const { id } = useParams();
  const { skuPetz, isLoading, salechannels } = useSelector(
    state => state.skusPetz
  );
  const [showAudit, setShowAudit] = useState(false);

  useEffect(() => {
    dispatch(getWarehouseSaleChannels());
    if (id) {
      dispatch(getSkuPetz(id));
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (skuPetz?.sale_channels?.length > 0 && id) {
      setFieldsValue({
        sale_channels: skuPetz.sale_channels
      });
    } else {
      setFieldsValue({
        sale_channels: ZEEDOG_STORE ? ['zeedog'] : ['zeenow']
      });
    }
    // eslint-disable-next-line
  }, [skuPetz]);

  const onSubmit = () => {
    validateFields((err, values) => {
      if (err) return;
      const payload = { ...values };
      if (id) {
        dispatch(updateSkuPetz({ ...payload, id }));
      } else {
        dispatch(createSkuPetz(payload));
      }
    });
  };

  const formFields = [
    {
      label: 'EAN',
      value: 'ean',
      placeholder: 'Número do código de barras',
      required: true
    },
    {
      label: 'Código SKU Zee.Now',
      value: 'reference',
      placeholder: 'Código do SKU usado pela Zee.Now',
      required: true
    },
    {
      label: 'Código Produto Zee.Now',
      value: 'product_reference',
      placeholder: 'ID do produto usado pela Zee.Now',
      required: true
    },
    {
      label: 'Código SKU Petz',
      value: 'external_id',
      placeholder: 'ID do SKU usado pela Petz',
      required: true
    },
    // {
    //   label: 'Título',
    //   value: 'title',
    //   placeholder: 'Título do SKU'
    // },
    {
      label: 'Tamanho',
      value: 'description',
      placeholder: 'Descrição do SKU',
      required: true
    },
    {
      label: 'Peso',
      value: 'weight',
      placeholder: 'Peso do SKU',
      required: true
    },
    {
      label: 'Altura',
      value: 'height',
      placeholder: 'Altura do SKU',
      required: false
    },
    {
      label: 'Largura',
      value: 'width',
      placeholder: 'Largura do SKU',
      required: false
    },
    {
      label: 'Comprimento',
      value: 'length',
      placeholder: 'Comprimento do SKU',
      required: false
    }
  ];

  const actions = (
    <Menu>
      <Menu.Item key="0" onClick={() => setShowAudit(true)}>
        Histórico de atualizações
      </Menu.Item>
    </Menu>
  );

  const actionsExtra = (
    <Dropdown overlay={actions} trigger={['click']}>
      <span>
        <Icon type="ellipsis" key="ellipsis" style={{ fontSize: '30px' }} />
      </span>
    </Dropdown>
  );

  return (
    <>
      <Audit
        id={id}
        title="SKU PETZ"
        type="SkuPetz"
        visible={showAudit}
        setVisible={setShowAudit}
      />
      <Row type="flex" justify="space-between" align="middle">
        <Col span={20}>
          <h1>
            {id
              ? `Editar SKU ${skuPetz?.reference ? skuPetz.reference : ''}`
              : 'Novo SKU'}
          </h1>
        </Col>
      </Row>
      <Breadcrumb separator="" style={{ marginBottom: 24 }}>
        <Breadcrumb.Item>
          <Link to="/skus-petz">SKUs Petz</Link>
          <Breadcrumb.Separator>&gt; </Breadcrumb.Separator>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {id
            ? `SKU ${skuPetz?.reference ? skuPetz.reference : ''}`
            : 'Novo SKU'}
        </Breadcrumb.Item>
      </Breadcrumb>
      <div
        style={{
          borderRadius: '16px',
          border: '7px solid #ebebeb',
          minHeight: '48px'
        }}
      >
        <Card
          className="no-shadow"
          title="DETALHES DO SKU"
          extra={id ? actionsExtra : null}
        >
          <Form>
            {formFields.map(item => (
              <Row style={{ width: '50%' }} key={item.value}>
                <Form.Item colon={false} label={item.label}>
                  <Skeleton
                    active
                    title={false}
                    loading={id ? isLoading : false}
                    paragraph={{ rows: 1, width: 250 }}
                    className="ant-skeleton-input"
                  >
                    {getFieldDecorator(item.value, {
                      rules: [
                        {
                          required: item?.required,
                          message: 'Campo obrigatório'
                        }
                      ],
                      initialValue:
                        id && skuPetz?.id ? skuPetz[item.value] : undefined
                    })(
                      item.value === 'weight' ? (
                        <InputNumber
                          min={0}
                          step={0.1}
                          placeholder={item.placeholder}
                        />
                      ) : (
                        <Input placeholder={item.placeholder} />
                      )
                    )}
                  </Skeleton>
                </Form.Item>
              </Row>
            ))}
            <Row style={{ width: '50%' }}>
              <Form.Item colon={false} label="Canais de Venda">
                <Skeleton
                  active
                  title={false}
                  loading={isLoading}
                  paragraph={{ rows: 1, width: 250 }}
                  className="ant-skeleton-input"
                >
                  {getFieldDecorator(
                    'sale_channels',
                    {}
                  )(
                    <Select
                      placeholder="Selecione um ou mais canais de venda"
                      showSearch
                      filterOption={handleSelectSearch}
                      mode="multiple"
                    >
                      {salechannels.map(item => (
                        <Select.Option
                          key={`warehouse-salechannel-${item.slug}`}
                          value={item.slug}
                        >
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Skeleton>
              </Form.Item>
            </Row>
          </Form>
          <Button
            className="ant-btn ant-btn-primary"
            onClick={onSubmit}
            // disabled={
            //   !fieldValues.type ||
            //   !fieldValues.type_value
            // }
            loading={isLoading}
          >
            Salvar
          </Button>
        </Card>
      </div>
    </>
  );
};

SkuPetz.propTypes = {
  // isNew: PropTypes.bool.isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    getFieldsValue: PropTypes.func,
    validateFields: PropTypes.func,
    setFieldsValue: PropTypes.func
  }).isRequired
};

const SkuPetzForm = Form.create({ name: 'SkuPetz ' })(SkuPetz);

export default SkuPetzForm;
