import { takeEvery, put, call, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { errorAlert, successAlert } from '@/src/utils/notifications';
import {
  setAssemblings,
  setLoading,
  setAssembling,
  setAssemblingProducts,
  createAssembling,
  updateAssembling,
  getAssemblings,
  getAssembling,
  // getAssemblingProducts,
  archiveAssembling,
  unarchiveAssembling,
  orderByDragNDrop,
  exportCSVAssemblings,
  importCSVAssemblings,
  exportCSVAssemblingProducts,
  importCSVAssemblingProducts
} from '@/src/store/modules/assemblings/slice';
import {
  getAssemblingsList as getAssemblingsListRequest,
  createAssembling as createAssemblingRequest,
  getAssemblingData as getAssemblingDataRequest,
  getAssemblingProducts as getAssemblingProductsRequest,
  updateAssembling as updateAssemblingRequest,
  archiveAssembling as archiveAssemblingRequest,
  unarchiveAssembling as unarchiveAssemblingRequest,
  orderByDragNDrop as orderByDragNDropRequest
} from '@/src/api/assemblings';
import {
  exportCSVAssemblings as exportCSVAssemblingsRequest,
  exportCSVAssemblingProducts as exportCSVAssemblingProductsRequest
} from '@/src/api/export-file';
import {
  importCSVAssemblings as importCSVAssemblingsRequest,
  importCSVAssemblingProducts as importCSVAssemblingProductsRequest
} from '@/src/api/import-file';

import {
  getAssemblingsQueryUrlState,
  getAssemblingsQueryState,
  getRouterState
} from '@/src/store/selectors';

function* getAssemblingsSaga({ payload }) {
  try {
    yield put(setLoading(true));
    const response = yield call(getAssemblingsListRequest, payload);
    const count =
      response?.data?.meta?.count > 0 ? response.data.meta.count : 0;
    yield put(
      setAssemblings({
        assemblings: response.data.data,
        count,
        query: payload.query
      })
    );
    const queryUrl = yield select(getAssemblingsQueryUrlState);
    const routerState = yield select(getRouterState);
    if (routerState.location.search !== queryUrl) {
      yield put(push(queryUrl));
    }
    yield put(setLoading(false));
  } catch (err) {
    yield put(setLoading(false));
    errorAlert(err, 'Não foi possível carregar as coleções');
  }
}

function* createAssemblingSaga({ payload }) {
  try {
    yield put(setLoading(true));
    yield call(createAssemblingRequest, payload);
    yield put(setLoading(false));
    yield put(push('/colecoes'));
    successAlert('Nova coleção criada');
  } catch (err) {
    yield put(setLoading(false));
    errorAlert(err, 'Não foi possível criar a coleção');
  }
}

function* getAssemblingSaga({ payload }) {
  try {
    yield put(setLoading(true));
    const response = yield call(getAssemblingDataRequest, payload);
    yield put(setAssembling(response.data.data));
    const products = yield call(getAssemblingProductsRequest, payload);
    yield put(setAssemblingProducts(products.data.data));
    yield put(setLoading(false));
  } catch (err) {
    yield put(setLoading(false));
    errorAlert(err, 'Não foi possível carregar os dados da coleção');
  }
}

// function* getAssemblingProductsSaga({ payload }) {
//   try {
//     const response = yield call(getAssemblingProductsRequest, payload);
//     yield put(setAssemblingProducts(response.data.data));
//     yield put(setLoading(false));
//   } catch (err) {
//     yield put(setLoading(false));
//     errorAlert(err, 'Não foi possível carregar os produtos da coleção');
//   }
// }

function* updateAssemblingSaga({ payload }) {
  try {
    yield put(setLoading(true));
    yield call(updateAssemblingRequest, payload);
    yield put(push('/colecoes'));
    successAlert('Os dados da coleção foram atualizados');
    yield put(setLoading(false));
  } catch (err) {
    yield put(setLoading(false));
    errorAlert(err, 'Não foi possível salvar as alterações');
  }
}

function* archiveAssemblingSaga({ payload }) {
  try {
    yield put(setLoading(true));
    yield call(archiveAssemblingRequest, payload);
    yield put(push('/colecoes'));
    successAlert('A coleção foi arquivada');
    yield put(setLoading(false));
  } catch (err) {
    yield put(setLoading(false));
    errorAlert(err, 'Não foi possível arquivar a coleção');
  }
}

function* unarchiveAssemblingSaga({ payload }) {
  try {
    yield put(setLoading(true));
    yield call(unarchiveAssemblingRequest, payload);
    yield put(push('/colecoes'));
    successAlert('A coleção foi desarquivada');
    yield put(setLoading(false));
  } catch (err) {
    yield put(setLoading(false));
    errorAlert(err, 'Não foi possível desarquivar a coleção');
  }
}

function* orderByDragNDropSaga({ payload }) {
  try {
    yield put(setLoading(true));
    yield call(orderByDragNDropRequest, payload);
    yield put(setLoading(false));
    successAlert('A ordenação foi atualizada');
  } catch (err) {
    yield put(setLoading(false));
    errorAlert(err, 'Não foi possível ordernar as coleções');
  }
}

function* exportCSVAssemblingsSaga() {
  try {
    const response = yield call(exportCSVAssemblingsRequest);
    const blob = new Blob([response.data]);
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'colecoes.csv';
    document.body.appendChild(a);
    a.click();
  } catch (err) {
    // console.log(err);
    errorAlert(err);
  }
}

function* importCSVAssemblingsSaga({ payload }) {
  try {
    yield put(setLoading(true));
    const data = new FormData();
    data.append('file', payload);
    yield call(importCSVAssemblingsRequest, data);
    const query = yield select(getAssemblingsQueryState);
    const response = yield call(getAssemblingsListRequest, { query });
    const count =
      response?.data?.meta?.count > 0 ? response.data.meta.count : 0;
    yield put(
      setAssemblings({
        assemblings: response.data.data,
        count,
        query
      })
    );
    yield put(setLoading(false));
    successAlert('A ordenação foi atualizada');
  } catch (err) {
    yield put(setLoading(false));
    errorAlert(err);
  }
}

function* exportCSVAssemblingProductsSaga({ payload }) {
  try {
    const label = payload.name
      .normalize('NFD')
      .replace(/\s/g, '')
      .replace(/[\u0300-\u036f]/g, '');
    const response = yield call(exportCSVAssemblingProductsRequest, payload.id);
    const blob = new Blob([response.data]);
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${label}.csv`;
    document.body.appendChild(a);
    a.click();
  } catch (err) {
    // console.log(err);
    errorAlert(err);
  }
}

function* importCSVAssemblingProductsSaga({ payload }) {
  try {
    const { id, file } = payload;
    yield put(setLoading(true));
    const data = new FormData();
    data.append('file', file);
    yield call(importCSVAssemblingProductsRequest, { id, data });
    const products = yield call(getAssemblingProductsRequest, id);
    yield put(setAssemblingProducts(products.data.data));
    yield put(setLoading(false));
    successAlert('A seleção de produtos foi atualizada');
  } catch (err) {
    yield put(setLoading(false));
    errorAlert(err);
  }
}

function* highlightsSaga() {
  yield takeEvery(getAssemblings, getAssemblingsSaga);
  yield takeEvery(createAssembling, createAssemblingSaga);
  yield takeEvery(getAssembling, getAssemblingSaga);
  // yield takeEvery(getAssemblingProducts, getAssemblingProductsSaga);
  yield takeEvery(updateAssembling, updateAssemblingSaga);
  yield takeEvery(archiveAssembling, archiveAssemblingSaga);
  yield takeEvery(unarchiveAssembling, unarchiveAssemblingSaga);
  yield takeEvery(orderByDragNDrop, orderByDragNDropSaga);
  yield takeEvery(exportCSVAssemblings, exportCSVAssemblingsSaga);
  yield takeEvery(importCSVAssemblings, importCSVAssemblingsSaga);
  yield takeEvery(exportCSVAssemblingProducts, exportCSVAssemblingProductsSaga);
  yield takeEvery(importCSVAssemblingProducts, importCSVAssemblingProductsSaga);
}

export default highlightsSaga;
