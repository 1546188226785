import axios from 'axios';
import { notification } from 'antd';
import bugsnag from '@/src/services/bugsnag';

const errorMessage = {
  'order.already_started_by_another_user':
    'Pedido já iniciado por outro usuário',
  'error Cannot associate price table with end date':
    'Não é permitido adicionar uma nova tabela de preços com vigência definida',
  'errors phone number already exists':
    'O telefone informado já está associado a outro cliente. Consulte pelo número para encontrá-lo.'
};

export function errorAlert(err, message = false) {
  // console.log(err, 'err');
  bugsnag.notify(err);
  if (axios.isCancel(err) || window.location.pathname === '/') return false;
  if (err.response) {
    // console.log(err.response);
    const error = err.response.data?.errors
      ? err.response.data?.errors[0]
      : {
          status:
            err.response.data?.status || err.response.status || err.code || '',
          title:
            err.response.data?.error ||
            err.response.statusText ||
            err.message ||
            ''
        };
    return notification.error({
      message: `Erro ${error.status ||
        err.response.data?.status ||
        err.response.status ||
        ''}`,
      duration: 4,
      className: 'error',
      description:
        errorMessage[error.title] ||
        errorMessage[error.detail] ||
        error.title ||
        error.detail ||
        message
    });
  }
  return notification.error({
    message: `Erro`,
    duration: 0,
    className: 'error',
    description: window.location.href.includes('expedicao?view=lines')
      ? 'Ocorreu um erro de conexão. Recarregue a página para atualizar os pedidos.'
      : 'Erro de conexão com o servidor.'
  });
}

export function successAlert(message) {
  const description = message || 'Sucesso';
  return notification.success({
    message: `Sucesso!`,
    duration: 4,
    className: 'success',
    description
  });
}
